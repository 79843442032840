@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
html,
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
html {
  background-color: #1b1b1b;
}
body {
  background-color: #2d2d2d;
  transform: translateZ(0);
  transform-origin: left top;
  box-shadow: 0px 4px 13px 9px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
#root {
  height: 100%;
}
.scrolling {
  -webkit-overflow-scrolling: touch;
}

.fade-enter {
  opacity: 0;
  z-index: 50 !important;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity .4s;
}
.fade-exit {
  opacity: 0;
  z-index: 1 !important;
}
.fade-exit.fade-exit-active {
  opacity: 1;
  z-index: 1 !important;
}



/* 以下是URL的样式*/
a { text-decoration: none; }
a:link {color: #30b1fc}		/* 未访问的链接 */
a:visited {color: #30b1fc}	/* 已访问的链接 */
a:hover {color: #ffffff}	/* 鼠标移动到链接上 */
a:active {color: #00a2ff}	/* 选定的链接 */

/* 以下是二级页面的容器样式 */
.underc{
  color: white;
  height: 150%;
  grid-column: 2 / 8;
}

.sector {
  display: grid;
  grid-template-columns: 1fr repeat(6, 142px) 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  color: white;
  background-color: #1d1d1d;
  padding: 0 0 50px 0;
}

.card_container{
  grid-column: 2/9;
  /* display: flex;
  flex-direction: row; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* white-space: nowrap; */
}
.card{
  display: inline-block;
  padding: 20px;
  width: 300px;
  height: auto;
  margin-right: 20px;
  background-color: rgb(0, 0, 0);
}

.footer{
  display: grid;
  grid-template-columns: 1fr repeat(2, 476px) 1fr;
  padding-top: 36px;
  background-color: #1d1d1d;
}
.left_nav{
  grid-column: 1/3;
  text-align: left;
  margin-left: 0;
  width: 100%;
  background-color: #242424;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 476px;
  border-right: 1px solid #1d1d1d;
  background-repeat: no-repeat;
  /* object-fit: cover;
  object-position: center; */
}
.right_nav{
  grid-column: 3/5;
  text-align: right;
  margin-right: 0;
  width: 100%;
  background-color: #242424;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 476px 1fr ;
}

.left_nav:hover, .right_nav:hover{
  background-color: #00a075;
  border-color: 1px solid #00bb8c;
}

/* 以下是二级页面的分割线样式 */
.sectorhr { 
  grid-column: 2 / 8;
  display: block; 
  background-color: #686868;
  overflow: hidden; 
  border: none; 
  height: 0.5px;
  width: 100%;
  margin: 0 0 80px 0;
}
.chapterhr { 
  grid-column: 4 / 8;
  display: block; 
  background-color: #686868;
  overflow: hidden; 
  border: none; 
  height: 0.5px;
  width: 100%;
  margin: 40px 0 60px 0;
}

/* 以下是二级页面的文本样式 */
.index {
  padding-top: 70px;
  font-family: "Ruda-Regular";
  font-size: 15px;
  grid-column: 2 / 8;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.big_title {
  grid-column: 2 / 8;
  font-family: "Cambon";
  font-size: 35px;
  margin-bottom: 60px;
}

.small_title {
  font-family: "Cambon";
  /* font-family: 'Ruda-ExtraBold'; */
  /* font-family: 'PT Sans', sans-serif; */
  font-size: 25px;
  line-height: 100%;
  word-spacing: 2px;
  /* line-height: 160%; */
  margin: 0 0 30px 0;
  text-transform:capitalize;
}

.subtitle {
  font-family: "Fira Sans";
  font-size: 16px;
  line-height: 164%;
  letter-spacing: 0.025em;
  margin: 15px 0 10px 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
}

.text {
  /* font-family: "Fira Sans"; */
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.025em;
  margin: 0 0 20px 0;
  color: #ffffff;
}

ol, li {margin-left: 15px; padding-left: 0;}

.direction{
  font-family: "Ruda-Regular";
  font-size: 15px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.name{
  font-family: 'Ruda-ExtraBold';
  font-size: 25px;
  text-transform: uppercase;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 40px;
  color: #888888;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-back:before {
  content: "\e63e";
}
.icon-next:before {
  content: "\e63f";
}

.left_nav:hover .iconfont{
  color: white;
}
.right_nav:hover .iconfont{
  color: white;
}

/* 以下是二级页面的网格系统 */
.last{
  grid-column: 2 / 3;
}
.next{
  grid-column: 1 / 2;
}

.left4 {
  grid-column: 2 / 6;
}

.right4 {
  grid-column: 4 / 8;
}

.left5 {
  grid-column: 2 / 7;
}
.right5 {
  grid-column: 3 / 8;
}

.left3 {
  grid-column: 2 / 5;
}

.right3 {
  grid-column: 5 / 8;
}

.fullleft4 {
  grid-column: 1 / 5;
}

.fullright4 {
  grid-column: 5 / 9;
}

.left2 {
  grid-column: 2 / 4;
}
.left1 {
  grid-column: 2 / 3;
}

.middle2 {
  grid-column: 4 / 6;
}

.right2 {
  grid-column: 6 / 8;
}

.right1 {
  grid-column: 7 / 8;
}

.full_width {
  grid-column: 1/9;
}

.inner_full_width {
  grid-column: 2/8;
}

/* 以下是快捷间距 */
.bottom_20 {
  margin-bottom: 20px;
}
.bottom_40 {
  margin-bottom: 40px;
}
.bottom_60 {
  margin-bottom: 60px;
}
.bottom_80 {
  margin-bottom: 80px;
}

.top_20{
  margin-top: 20px;
}
.top_40{
  margin-top: 40px;
}
.top_60{
  margin-top: 60px;
}

/* 以下是快捷媒体尺寸 */
.innerimg {
  margin: 7px 0 7px 0;
  width: 100%;
  /* width: 952px; */
  height: auto;
}

.outimg {
  margin: 80px 0 80px 0;
  width: 100%;
  height: auto;
}

.inner_video {
  width: 100%;
  height: auto;
  margin: 60px 0 60px 0;
}

video[poster]{
  object-fit: cover;
  }
video{
  outline:none;
}

.swiper-demo-index {
  padding-top: 70px;
  grid-column: 2 / 8;
  margin-bottom: 10px;
  height: 600px;
}

.bone-swiper-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.bone-swiper-titles {
  height: 44px;
  display: flex;
  line-height: 44px;
  font-size: 16px;
}
.bone-swiper-title {
  padding: 0 23px;
  cursor: pointer;
  background: #595959;
  opacity: 1;
  width: auto;
  height: auto;
  border-radius: 0;
  outline: none;
}
.bone-swiper-title-active {
  background: #000;
}
.bone-swiper-title + .bone-swiper-title {
  border-left: 1px solid #6a6a6a;
}
.bone-swiper-instance {
  flex: 1 1;
}
.bone-swiper-instance .swiper-slide-image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.bone-swiper-instance:hover .swiper-slide-description {
  opacity: 1;
}
.bone-swiper-instance .swiper-slide-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 39px 16px 16px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 15px;
  color: white;
  opacity: 0;
  transition: opacity .4s;
}
.bone-swiper-countdown svg {
  position: absolute;
  z-index: 3;
  top: 65px;
  right: 25px;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.bone-swiper-countdown svg circle {
  stroke-dasharray: 95px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 5px;
  fill: none;
}
.bone-swiper-countdown svg circle.inner {
  stroke-dashoffset: -95px;
  stroke: white;
  position: relative;
  z-index: 2;
}
.bone-swiper-countdown svg circle.border {
  stroke: #6d6d6d;
}
@font-face {
  font-family: 'Ruda-Black';
  font-display: swap;
  src: url(/static/media/Ruda-Black.3616bbc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruda-Bold';
  font-display: swap;
  src: url(/static/media/Ruda-Bold.c2dd3e56.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruda-ExtraBold';
  font-display: swap;
  src: url(/static/media/Ruda-ExtraBold.2df016e6.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruda-Medium';
  font-display: swap;
  src: url(/static/media/Ruda-Medium.a31a36c8.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruda-Regular';
  font-display: block;
  src: url(/static/media/Ruda-Regular.befc4852.ttf) format('truetype');
}
@font-face {
  font-family: 'Ruda-SemiBold';
  font-display: swap;
  src: url(/static/media/Ruda-SemiBold.19d4732c.ttf) format('truetype');
}

@font-face {
  font-family: 'Cambon';
  font-display: swap;
  src: url(/static/media/Cambon-SemiBold.a6647e51.woff2) format('woff2');
}

/* @font-face {
  font-family: 'Calluna';
  font-display: swap;
  src: url('../assets/fonts/calluna/Calluna-Regular.woff2') format('woff2');
} */

.ff-regular {
  font-family: 'Ruda-Regular';
}
.ff-black {
  font-family: 'Ruda-Black';
}
.ff-bold {
  font-family: 'Ruda-Bold';
}
.ff-extra-bold {
  font-family: 'Ruda-ExtraBold';
}
.ff-medium {
  font-family: 'Ruda-Medium';
}
.ff-semi-bold {
  font-family: 'Ruda-SemiBold';
}

@font-face {font-family: "iconfont";
  src: url(/static/media/iconfont.8db0158e.eot); /* IE9 */
  src: url(/static/media/iconfont.8db0158e.eot#iefix) format('embedded-opentype'), 
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALAAAsAAAAABsAAAAJ0AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCfgqBIIEWATYCJAMMCwgABCAFhG0HSBvmBciemjwp0Wr8DPGwQRUgAKd4+Nrvd+7ufsMtqXafTiJpwlMhQcik8kMhkckeEuldzeV+iYRe/8/yVYkUoArt3V/oMpdNEYQLGFlfob9C4vgOO83Cc0ES/BvBgGfnW4AF8hJwot7nuZzeBDo7uqs+UE5rbRt+1AswDqgX0F4YbeESybyHeLWD7MR5Ah1mNNSO13cPYEphTwvEo5I2TBUyiiLWm0K9YW0RL2qa6SkNgefo+/GnF1Mktcyee3K95sHip/v0+bHiLCBkCbi5hoxVoBCnjZUTo0LkRnVUxsyCfQ0LPn1V8ekCn9grn4i/zm3sAotoTnek9SxuTYsbJJBBvVibBzYZO/TOEIPJ8leyaqNwr5JU5F5lWv1SlLVnQaXy8jKaalhD/XaX6cOTV/fFtrHB7BBL4L1p5BVA1U5/OUbk7y9qCF7rZx8LMjc7C/jA/xOQrtoF6D7NM0SDv6UlthVDb3tz0TSexoplg4RQAlVSXMVODPgebxsmtxKahkfEGqYzZE2zZGFXUdNpF3VNe+iwYmNzp0HGqCgtLHsDhD7vSHq0kfX5JAv7hZoR/6jri1F0OA22Z6f5UDOzIZvJQ+cKlatj4fp5Jm7eI5mFtsnrI+GITKry2G11yvUNisnMsSA9lz1mgcLoCNfhMgpDjYnRPrncGjAn/XZbNN2o5eoIZhwxiI0RD3JcQYpLi4mgO5upfH4PkTIhm+mQ1JWPECOl+kddLR0CuWGJSZJ7uSZ1TuphTECCoUXQOowioZCGkuZRPuJiLYMRpURf2+kkqJbW+rbo+/ZAB/vgHClyFN23OuNM+cqOWWcLaxTzVZx3AwA=') format('woff2'),
  url(/static/media/iconfont.db75a125.woff) format('woff'),
  url(/static/media/iconfont.7ee3b3f1.ttf) format('truetype'), 
  url(/static/media/iconfont.73d45110.svg#iconfont) format('svg'); /* iOS 4.1- */
}

