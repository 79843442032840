.bone-swiper-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.bone-swiper-titles {
  height: 44px;
  display: flex;
  line-height: 44px;
  font-size: 16px;
}
.bone-swiper-title {
  padding: 0 23px;
  cursor: pointer;
  background: #595959;
  opacity: 1;
  width: auto;
  height: auto;
  border-radius: 0;
  outline: none;
}
.bone-swiper-title-active {
  background: #000;
}
.bone-swiper-title + .bone-swiper-title {
  border-left: 1px solid #6a6a6a;
}
.bone-swiper-instance {
  flex: 1;
}
.bone-swiper-instance .swiper-slide-image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.bone-swiper-instance:hover .swiper-slide-description {
  opacity: 1;
}
.bone-swiper-instance .swiper-slide-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 39px 16px 16px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 15px;
  color: white;
  opacity: 0;
  transition: opacity .4s;
}
.bone-swiper-countdown svg {
  position: absolute;
  z-index: 3;
  top: 65px;
  right: 25px;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.bone-swiper-countdown svg circle {
  stroke-dasharray: 95px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 5px;
  fill: none;
}
.bone-swiper-countdown svg circle.inner {
  stroke-dashoffset: -95px;
  stroke: white;
  position: relative;
  z-index: 2;
}
.bone-swiper-countdown svg circle.border {
  stroke: #6d6d6d;
}