@font-face {
  font-family: 'Ruda-Black';
  font-display: swap;
  src: url('../assets/fonts/ruda/Ruda-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Ruda-Bold';
  font-display: swap;
  src: url('../assets/fonts/ruda/Ruda-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Ruda-ExtraBold';
  font-display: swap;
  src: url('../assets/fonts/ruda/Ruda-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Ruda-Medium';
  font-display: swap;
  src: url('../assets/fonts/ruda/Ruda-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Ruda-Regular';
  font-display: block;
  src: url('../assets/fonts/ruda/Ruda-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Ruda-SemiBold';
  font-display: swap;
  src: url('../assets/fonts/ruda/Ruda-SemiBold.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

@font-face {
  font-family: 'Cambon';
  font-display: swap;
  src: url('../assets/fonts/cambon/Cambon-SemiBold.woff2') format('woff2');
}

/* @font-face {
  font-family: 'Calluna';
  font-display: swap;
  src: url('../assets/fonts/calluna/Calluna-Regular.woff2') format('woff2');
} */

.ff-regular {
  font-family: 'Ruda-Regular';
}
.ff-black {
  font-family: 'Ruda-Black';
}
.ff-bold {
  font-family: 'Ruda-Bold';
}
.ff-extra-bold {
  font-family: 'Ruda-ExtraBold';
}
.ff-medium {
  font-family: 'Ruda-Medium';
}
.ff-semi-bold {
  font-family: 'Ruda-SemiBold';
}

@font-face {font-family: "iconfont";
  src: url('../assets/fonts/iconfont/back/iconfont.eot?t=1609226266667'); /* IE9 */
  src: url('../assets/fonts/iconfont/back/iconfont.eot?t=1609226266667#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALAAAsAAAAABsAAAAJ0AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCfgqBIIEWATYCJAMMCwgABCAFhG0HSBvmBciemjwp0Wr8DPGwQRUgAKd4+Nrvd+7ufsMtqXafTiJpwlMhQcik8kMhkckeEuldzeV+iYRe/8/yVYkUoArt3V/oMpdNEYQLGFlfob9C4vgOO83Cc0ES/BvBgGfnW4AF8hJwot7nuZzeBDo7uqs+UE5rbRt+1AswDqgX0F4YbeESybyHeLWD7MR5Ah1mNNSO13cPYEphTwvEo5I2TBUyiiLWm0K9YW0RL2qa6SkNgefo+/GnF1Mktcyee3K95sHip/v0+bHiLCBkCbi5hoxVoBCnjZUTo0LkRnVUxsyCfQ0LPn1V8ekCn9grn4i/zm3sAotoTnek9SxuTYsbJJBBvVibBzYZO/TOEIPJ8leyaqNwr5JU5F5lWv1SlLVnQaXy8jKaalhD/XaX6cOTV/fFtrHB7BBL4L1p5BVA1U5/OUbk7y9qCF7rZx8LMjc7C/jA/xOQrtoF6D7NM0SDv6UlthVDb3tz0TSexoplg4RQAlVSXMVODPgebxsmtxKahkfEGqYzZE2zZGFXUdNpF3VNe+iwYmNzp0HGqCgtLHsDhD7vSHq0kfX5JAv7hZoR/6jri1F0OA22Z6f5UDOzIZvJQ+cKlatj4fp5Jm7eI5mFtsnrI+GITKry2G11yvUNisnMsSA9lz1mgcLoCNfhMgpDjYnRPrncGjAn/XZbNN2o5eoIZhwxiI0RD3JcQYpLi4mgO5upfH4PkTIhm+mQ1JWPECOl+kddLR0CuWGJSZJ7uSZ1TuphTECCoUXQOowioZCGkuZRPuJiLYMRpURf2+kkqJbW+rbo+/ZAB/vgHClyFN23OuNM+cqOWWcLaxTzVZx3AwA=') format('woff2'),
  url('../assets/fonts/iconfont/back/iconfont.woff?t=1609226266667') format('woff'),
  url('../assets/fonts/iconfont/back/iconfont.ttf?t=1609226266667') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('../assets/fonts/iconfont/back/iconfont.svg?t=1609226266667#iconfont') format('svg'); /* iOS 4.1- */
}
