html,
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
html {
  background-color: #1b1b1b;
}
body {
  background-color: #2d2d2d;
  transform: translateZ(0);
  transform-origin: left top;
  box-shadow: 0px 4px 13px 9px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
#root {
  height: 100%;
}
.scrolling {
  -webkit-overflow-scrolling: touch;
}

.fade-enter {
  opacity: 0;
  z-index: 50 !important;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity .4s;
}
.fade-exit {
  opacity: 0;
  z-index: 1 !important;
}
.fade-exit.fade-exit-active {
  opacity: 1;
  z-index: 1 !important;
}



/* 以下是URL的样式*/
a { text-decoration: none; }
a:link {color: #30b1fc}		/* 未访问的链接 */
a:visited {color: #30b1fc}	/* 已访问的链接 */
a:hover {color: #ffffff}	/* 鼠标移动到链接上 */
a:active {color: #00a2ff}	/* 选定的链接 */

/* 以下是二级页面的容器样式 */
.underc{
  color: white;
  height: 150%;
  grid-column: 2 / 8;
}

.sector {
  display: grid;
  grid-template-columns: 1fr repeat(6, 142px) 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  color: white;
  background-color: #1d1d1d;
  padding: 0 0 50px 0;
}

.card_container{
  grid-column: 2/9;
  /* display: flex;
  flex-direction: row; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* white-space: nowrap; */
}
.card{
  display: inline-block;
  padding: 20px;
  width: 300px;
  height: auto;
  margin-right: 20px;
  background-color: rgb(0, 0, 0);
}

.footer{
  display: grid;
  grid-template-columns: 1fr repeat(2, 476px) 1fr;
  padding-top: 36px;
  background-color: #1d1d1d;
}
.left_nav{
  grid-column: 1/3;
  text-align: left;
  margin-left: 0;
  width: 100%;
  background-color: #242424;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 476px;
  border-right: 1px solid #1d1d1d;
  background-repeat: no-repeat;
  /* object-fit: cover;
  object-position: center; */
}
.right_nav{
  grid-column: 3/5;
  text-align: right;
  margin-right: 0;
  width: 100%;
  background-color: #242424;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 476px 1fr ;
}

.left_nav:hover, .right_nav:hover{
  background-color: #00a075;
  border-color: 1px solid #00bb8c;
}

/* 以下是二级页面的分割线样式 */
.sectorhr { 
  grid-column: 2 / 8;
  display: block; 
  background-color: #686868;
  overflow: hidden; 
  border: none; 
  height: 0.5px;
  width: 100%;
  margin: 0 0 80px 0;
}
.chapterhr { 
  grid-column: 4 / 8;
  display: block; 
  background-color: #686868;
  overflow: hidden; 
  border: none; 
  height: 0.5px;
  width: 100%;
  margin: 40px 0 60px 0;
}

/* 以下是二级页面的文本样式 */
.index {
  padding-top: 70px;
  font-family: "Ruda-Regular";
  font-size: 15px;
  grid-column: 2 / 8;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.big_title {
  grid-column: 2 / 8;
  font-family: "Cambon";
  font-size: 35px;
  margin-bottom: 60px;
}

.small_title {
  font-family: "Cambon";
  /* font-family: 'Ruda-ExtraBold'; */
  /* font-family: 'PT Sans', sans-serif; */
  font-size: 25px;
  line-height: 100%;
  word-spacing: 2px;
  /* line-height: 160%; */
  margin: 0 0 30px 0;
  text-transform:capitalize;
}

.subtitle {
  font-family: "Fira Sans";
  font-size: 16px;
  line-height: 164%;
  letter-spacing: 0.025em;
  margin: 15px 0 10px 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
}

.text {
  /* font-family: "Fira Sans"; */
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.025em;
  margin: 0 0 20px 0;
  color: #ffffff;
}

ol, li {margin-left: 15px; padding-left: 0;}

.direction{
  font-family: "Ruda-Regular";
  font-size: 15px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.name{
  font-family: 'Ruda-ExtraBold';
  font-size: 25px;
  text-transform: uppercase;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 40px;
  color: #888888;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-back:before {
  content: "\e63e";
}
.icon-next:before {
  content: "\e63f";
}

.left_nav:hover .iconfont{
  color: white;
}
.right_nav:hover .iconfont{
  color: white;
}

/* 以下是二级页面的网格系统 */
.last{
  grid-column: 2 / 3;
}
.next{
  grid-column: 1 / 2;
}

.left4 {
  grid-column: 2 / 6;
}

.right4 {
  grid-column: 4 / 8;
}

.left5 {
  grid-column: 2 / 7;
}
.right5 {
  grid-column: 3 / 8;
}

.left3 {
  grid-column: 2 / 5;
}

.right3 {
  grid-column: 5 / 8;
}

.fullleft4 {
  grid-column: 1 / 5;
}

.fullright4 {
  grid-column: 5 / 9;
}

.left2 {
  grid-column: 2 / 4;
}
.left1 {
  grid-column: 2 / 3;
}

.middle2 {
  grid-column: 4 / 6;
}

.right2 {
  grid-column: 6 / 8;
}

.right1 {
  grid-column: 7 / 8;
}

.full_width {
  grid-column: 1/9;
}

.inner_full_width {
  grid-column: 2/8;
}

/* 以下是快捷间距 */
.bottom_20 {
  margin-bottom: 20px;
}
.bottom_40 {
  margin-bottom: 40px;
}
.bottom_60 {
  margin-bottom: 60px;
}
.bottom_80 {
  margin-bottom: 80px;
}

.top_20{
  margin-top: 20px;
}
.top_40{
  margin-top: 40px;
}
.top_60{
  margin-top: 60px;
}

/* 以下是快捷媒体尺寸 */
.innerimg {
  margin: 7px 0 7px 0;
  width: 100%;
  /* width: 952px; */
  height: auto;
}

.outimg {
  margin: 80px 0 80px 0;
  width: 100%;
  height: auto;
}

.inner_video {
  width: 100%;
  height: auto;
  margin: 60px 0 60px 0;
}

video[poster]{
  object-fit: cover;
  }
video{
  outline:none;
}

.swiper-demo-index {
  padding-top: 70px;
  grid-column: 2 / 8;
  margin-bottom: 10px;
  height: 600px;
}
